import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyB-2YpnteiIeqoqhbx1V_RNnpQq1ZpkQ0Y",
  authDomain: "timed-todos-e6a6e.firebaseapp.com",
  projectId: "timed-todos-e6a6e",
  storageBucket: "timed-todos-e6a6e.appspot.com",
  messagingSenderId: "113211444721",
  appId: "1:113211444721:web:406b5ebf00c245ce658850",
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

export { db, auth }
