import React, { useEffect, useState } from "react"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { RootState, useAppDispatch } from "../../state/store"
import { asyncLogin } from "../../state/auth/authReducer"
import { useAuth } from "./useAuth"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const useDispatch = useAppDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const handleLogin = async (e: any) => {
    try {
      console.log("email", email)
      console.log("password", password)
      await useDispatch(asyncLogin({ email, password }))
      // Handle successful login (e.g., redirect to a dashboard)
    } catch (error: any) {
      setError(error.message)
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/") // Redirect to home page
    }
  }, [isLoggedIn, navigate])

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Login</h2>
          {error && <p className="text-red-500">{error}</p>}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              placeholder="email"
              className="input input-bordered"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              placeholder="password"
              className="input input-bordered"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-control mt-6">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
