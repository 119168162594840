import { useSelector } from "react-redux"
import { RootState } from "../../state/store"
import { useEffect, useState } from "react"
import { ITodo } from "../../state/todos/todosSlice"
import { addMinutes, differenceInSeconds, format } from "date-fns"

interface ITimeLeft {
  hours: number
  minutes: number
  seconds: number
}

const colorAt100 = "#03FF54"
const colorAt75 = "#97FF71"
const colorAt50 = "#C9FF71"
const colorAt25 = "#FFEA71"
const colorAt0 = "#FF7171"

const getColorByPercentage = (percentage: number) => {
  if (percentage >= 75) return colorAt100
  if (percentage >= 50) return colorAt75
  if (percentage >= 25) return colorAt50
  if (percentage >= 0) return colorAt25
  return colorAt0
}

const timeLeftToPercentage = (
  deadline: Date,
  timeRemaining: number, // original time in minutes
  timeLeft: ITimeLeft,
) => {
  // Convert the original time set for the task to seconds
  const totalTaskTimeInSeconds = timeRemaining * 60

  // Convert the current time left to seconds
  const currentTimeLeftInSeconds =
    timeLeft.hours * 3600 + timeLeft.minutes * 60 + timeLeft.seconds

  // Calculate the percentage of time left
  const percentageTimeLeft =
    (currentTimeLeftInSeconds / totalTaskTimeInSeconds) * 100

  return percentageTimeLeft
}

export function Countdown({ id }: { id: string }) {
  const todos = useSelector((state: RootState) => state.todos.items)
  const [todo, setTodo] = useState<ITodo | null>(null)
  const [timeLeft, setTimeLeft] = useState<ITimeLeft>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [color, setColor] = useState<string>(colorAt100)

  useEffect(() => {
    if (!id) return

    const foundTodo = todos.find((todo: ITodo) => todo.id === id)
    setTodo(foundTodo || null)

    const updateCountdown = () => {
      if (foundTodo && foundTodo.createdAt && foundTodo.timeRemaining > 0) {
        const now = new Date()
        const deadline = addMinutes(
          foundTodo.createdAt,
          foundTodo.timeRemaining,
        )
        const totalSeconds = differenceInSeconds(deadline, now)

        if (totalSeconds <= 0) {
          clearInterval(intervalId)
          setTimeLeft({ hours: 0, minutes: 0, seconds: 0 })
          return
        }

        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60

        setTimeLeft({
          hours,
          minutes,
          seconds,
        })
        // Update the color.
        const percentage = timeLeftToPercentage(
          deadline,
          foundTodo.timeRemaining,
          {
            hours,
            minutes,
            seconds,
          },
        )
        setColor(getColorByPercentage(percentage))
      }
    }

    const intervalId = setInterval(updateCountdown, 1000)

    return () => clearInterval(intervalId)
  }, [id])

  if (!todo) return null

  return (
    <span
      className="countdown font-mono text-base p-1 rounded-md bg-opacity-5"
      style={{ backgroundColor: color }}
    >
      <span style={getStyle(timeLeft.hours)}>{timeLeft.hours}</span>h
      <span style={getStyle(timeLeft.minutes)}>{timeLeft.minutes}</span>m
      <span style={getStyle(timeLeft.seconds)}>{timeLeft.seconds}</span>s
    </span>
  )
}

const getStyle = (val: number) => {
  return {
    "--value": val,
  } as React.CSSProperties
}
