import { useSelector } from "react-redux"
import { ITodo, asyncRemoveTodo } from "../../state/todos/todosSlice"
import { RootState, useAppDispatch } from "../../state/store"
import { Countdown } from "./Countdown"
import {
  faTrash,
  faPlay,
  faPause,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export function TodosList() {
  const todos = useSelector((state: RootState) => state.todos.items)
  const useDispatch = useAppDispatch()

  return (
    <div className=" border-top border-t-2 border-gray-200 w-full flex flex-col justify-start align-top">
      <div className="p-4 flex flex-col gap-3">
        {todos.map((todo: ITodo) => (
          <div
            key={todo.id}
            className="flex lg:flex-row flex-col justify-start lg:items-center items-start gap-4"
          >
            <Countdown id={todo.id} />
            <div className="flex flex-row justify-between lg:items-center items-start w-full">
              <span className="text-base w-full text-left pr-2">
                {todo.text}
              </span>
              <div className="flex justify-start items-center gap-2">
                <FontAwesomeIcon
                  className="text-blue-400 text-base font-bold underline cursor-pointer"
                  onClick={() => useDispatch(asyncRemoveTodo(todo.id))}
                  icon={faPlay}
                />
                <FontAwesomeIcon
                  className="text-orange-400 text-base font-bold underline cursor-pointer"
                  onClick={() => useDispatch(asyncRemoveTodo(todo.id))}
                  icon={faPause}
                />
                <FontAwesomeIcon
                  className="text-green-400 text-base font-bold underline cursor-pointer"
                  onClick={() => useDispatch(asyncRemoveTodo(todo.id))}
                  icon={faCheck}
                />
                <FontAwesomeIcon
                  className="text-red-400 text-base font-bold underline cursor-pointer"
                  onClick={() => useDispatch(asyncRemoveTodo(todo.id))}
                  icon={faTrash}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
