import { configureStore } from "@reduxjs/toolkit"
import counterReducer from "./counter/counterSlice"
import todosReducer from "./todos/todosSlice"
import { useDispatch } from "react-redux"
import authReducer from "./auth/authReducer"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    todos: todosReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
