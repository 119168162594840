import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../../firebase"

const initialState: IAuthState = {
  isLoggedIn: false,
  user: null,
}

// Create the slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ uid: string; email: string | null }>,
    ) => {
      state.isLoggedIn = true
      state.user = action.payload
    },
    clearUser: (state) => {
      state.isLoggedIn = false
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncLogin.fulfilled,
      (state, action: PayloadAction<{ uid: string; email: string | null }>) => {
        state.isLoggedIn = true
        state.user = action.payload
      },
    )
  },
})

export const { setUser, clearUser } = authSlice.actions
export default authSlice.reducer
export { asyncLogin }

interface ILoginPayload {
  email: string
  password: string
}

interface IAuthState {
  isLoggedIn: boolean
  user: { uid: string; email: string | null } | null
}

// Define the async thunk
const asyncLogin = createAsyncThunk(
  "auth/login",
  async (payload: ILoginPayload, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        payload.email,
        payload.password,
      )
      console.log("returning", userCredential.user)
      const { uid, email } = userCredential.user
      return { uid, email }
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  },
)
