import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../state/store"
import {
  ITodo,
  ITodoPayload,
  asyncAddTodo,
  asyncFetchTodos,
} from "../../state/todos/todosSlice"
import { TodosList } from "./TodosList"
import { TimeSelector } from "./TimeSelector"
import { TodoTitle } from "./TodoTitle"

const emptyTodo: Partial<ITodo> = {
  text: "",
  timeRemaining: 0,
}

const Todos = () => {
  const [newTodo, setNewTodo] = useState<Partial<ITodo>>(emptyTodo)
  const [timeRemaining, setTimeRemaining] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const todos = useSelector((state: RootState) => state.todos.items)
  const useDispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.auth.user)

  const handleChange = (event: any) => {
    if (event.target.value.length >= 20) {
      setErrorMessage("Todo text must be less than 20 characters.")
    } else {
      setErrorMessage("")
    }
    // Update the new todo text.
    setNewTodo((prev: Partial<ITodo>) => ({
      ...prev,
      text: event.target.value,
      timeRemaining,
    }))
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      handleAddTodo()
    }
  }

  const handleTimeChange = (updatedTime: number) => {
    if (timeRemaining !== updatedTime) {
      setTimeRemaining(updatedTime)
    } else {
      setTimeRemaining(0)
    }
  }

  const handleAddTodo = () => {
    // Add the new todo to the list.
    useDispatch(
      asyncAddTodo({
        text: newTodo.text,
        timeRemaining,
        userId: user?.uid,
      } as ITodoPayload),
    )
    // Clear the input field.
    setTimeout(() => {
      setNewTodo(emptyTodo)
      // Clear the time remaining.
      setTimeRemaining(0)
      // Move the focus to the input field again.
      document.getElementsByTagName("input")[0].focus()
    }, 500)
  }

  useEffect(() => {
    useDispatch(asyncFetchTodos())
  }, [useDispatch])

  return (
    <div className="card w-full lg:w-2/4 bg-base-200 shadow-inner mb-5">
      <div className="card-body flex flex-col gap-4">
        {/* Header part. */}
        <div className="flex flex-col gap-4">
          <h2 className="card-title justify-center">
            Countdown for your tasks
          </h2>

          <div className="flex flex-col items-center w-full gap-4">
            <TodoTitle
              newTodoText={newTodo.text}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
            />
            <TimeSelector
              timeRemaining={timeRemaining}
              setTimeRemaining={handleTimeChange}
            />
            <button
              type="button"
              onClick={() => handleAddTodo()}
              className="inline-block cursor-pointer rounded-md bg-gray-800 px-4 py-3 text-center text-sm font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-gray-900 h-12 w-full max-w-xs"
            >
              Add
            </button>
          </div>

          {todos.length > 0 && <TodosList />}
        </div>
      </div>
    </div>
  )
}

export default Todos
