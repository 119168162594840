import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import LoginPage from "./components/Authentication/LoginPage"
import Todos from "./components/Todos/Todos"
import { ProtectedRoute } from "./components/Authentication/ProtectedRoute"
import { NotFoundPage } from "./components/NotFoundPage"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { onAuthStateChanged } from "firebase/auth"
import { clearUser, setUser } from "./state/auth/authReducer"
import { auth } from "../firebase"

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, dispatch an action to set the user in the Redux store
        const { uid, email } = user
        dispatch(setUser({ uid, email }))
      } else {
        // User is signed out, dispatch an action to clear the user from the Redux store
        dispatch(clearUser())
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [dispatch])

  return (
    <Router>
      <div className="App">
        <div className="mt-5 flex flex-col justify-start items-center">
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Todos />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}
export default App
