import { useEffect, useState } from "react"

const periods = [
  { id: 2, altText: "10m", text: "10 minutes", timeToAdd: 10 },
  { id: 3, altText: "30m", text: "30 minutes", timeToAdd: 30 },
  { id: 4, altText: "1h", text: "1 hour", timeToAdd: 60 },
  { id: 5, altText: "4h", text: "half a day", timeToAdd: 240 },
  { id: 6, altText: "8h", text: "1 day", timeToAdd: 1440 },
]

interface Props {
  timeRemaining: number
  setTimeRemaining: any
}

export function TimeSelector({ timeRemaining, setTimeRemaining }: Props) {
  const handleChange = (timeToAdd: number) => {
    setTimeRemaining(timeToAdd)
  }

  return (
    <div className="form-control w-full max-w-xs">
      <label className="label">
        <span className="label-text">
          Pick the time you need to accomplish the task
        </span>
      </label>
      <div className="flex flex-wrap">
        {periods.map((period) => (
          <button
            key={period.id}
            type="button"
            className="border border-black rounded-md p-2 m-2 w-12"
            style={{
              backgroundColor: timeRemaining === period.timeToAdd ? "gray" : "",
            }}
            onClick={() => handleChange(period.timeToAdd)}
          >
            {period.altText}
          </button>
        ))}
      </div>
    </div>
  )
}
