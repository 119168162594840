import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"
import { addDoc, collection, deleteDoc, doc, getDocs } from "firebase/firestore"
import { db } from "../../../firebase"

const initialState: TodosState = {
  items: [],
}

const todosSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    setTodos: (state, action: PayloadAction<ITodo[]>) => {
      state.items = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        asyncAddTodo.fulfilled,
        (state, action: PayloadAction<ITodo>) => {
          state.items.push(action.payload)
          console.log("async addTodo done:", action.payload)
        },
      )
      .addCase(
        asyncRemoveTodo.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.items = [
            ...state.items.filter((curr: ITodo) => curr.id !== action.payload),
          ]
        },
      )
      .addCase(
        asyncFetchTodos.fulfilled,
        (state, action: PayloadAction<ITodo[]>) => {
          state.items = [...action.payload]
        },
      )
  },
})

export const { setTodos } = todosSlice.actions

export default todosSlice.reducer

export const asyncAddTodo = createAsyncThunk(
  "todos/addTodo",
  async (payload: ITodoPayload, { rejectWithValue }) => {
    try {
      if (!payload.text || !payload.userId) {
        throw new Error("Text or userId is required")
      }

      const newTodo: Partial<ITodo> = {
        text: payload.text,
        timeRemaining: payload.timeRemaining || 0,
        userId: payload.userId,
        createdAt: Date.now(),
      }

      // Add the new todo to the database
      const docRef = await addDoc(collection(db, "todos"), newTodo)

      return {
        ...newTodo,
        id: docRef.id,
      } as ITodo
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const asyncRemoveTodo = createAsyncThunk(
  "todos/removeTodo",
  async (payload: string, { rejectWithValue }) => {
    try {
      if (!payload) {
        throw new Error("The uid for the todo to remove is required")
      }

      // Add the new todo to the database
      await deleteDoc(doc(db, "todos", payload))
      return payload
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const asyncFetchTodos = createAsyncThunk(
  "todos/fetchTodos",
  async (_, { rejectWithValue }) => {
    try {
      const querySnapshot = await getDocs(collection(db, "todos"))
      const todos: ITodo[] = []
      querySnapshot.forEach((doc) => {
        todos.push({
          id: doc.id,
          ...doc.data(),
        } as ITodo)
      })
      console.log("todos", todos)
      return todos
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export interface ITodo {
  id: string
  text: string
  timeRemaining: number
  userId?: string
  createdAt?: number
}

export interface ITodoPayload {
  text?: string
  timeRemaining?: number
  userId?: string
}

interface TodosState {
  items: ITodo[]
}
