interface Props {
  newTodoText: string | undefined
  handleChange: (event: any) => void
  handleKeyDown: (event: any) => void
}

export function TodoTitle({ newTodoText, handleChange, handleKeyDown }: Props) {
  return (
    <div className="form-control w-full max-w-xs">
      <label className="label">
        <span className="label-text">Give a title to your todo</span>
      </label>

      <input
        autoFocus
        value={newTodoText}
        className="input input-bordered w-full max-w-xs"
        onChange={(event: any) => handleChange(event)}
        onKeyDown={(event: any) => handleKeyDown(event)}
      />
    </div>
  )
}
